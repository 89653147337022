@tailwind base;
@tailwind components;
@tailwind utilities;

/* PACKAGE STYLES */
@import "swiper/css";
@import "swiper/css/effect-fade";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
@import "swiper/css/autoplay";
@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";
@import "react-big-calendar/lib/css/react-big-calendar.css";
@import "react-credit-cards-2/dist/es/styles-compiled.css";
@import "react-quill/dist/quill.snow.css";
@import "react-loading-skeleton/dist/skeleton.css";

/* FONTS */
@font-face {
  font-family: "ProximaNova-Thin";
  src: url("../assets/fonts/ProximaNova-Thin.woff");
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url("../assets/fonts/ProximaNova-Light.woff");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("../assets/fonts/ProximaNova-Regular.woff");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("../assets/fonts/ProximaNova-Semibold.woff");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("../assets/fonts/ProximaNova-Bold.woff");
}

@font-face {
  font-family: "ProximaNova-Extrabold";
  src: url("../assets/fonts/ProximaNova-Extrabld.woff");
}

.container-full-width {
  max-width: 100%;
}

.container {
  max-width: 150vh;
  margin: auto;
}

/* AUTHENTICATION */
.auth {
  height: 100vh;
  background: url("../assets/images/banners/authentication.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

/* DATEPICKER */
.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  border-top-left-radius: 1vh;
  border-bottom-left-radius: 1vh;
}

.rdrDateRangePickerWrapper .rdrCalendarWrapper {
  border-top-right-radius: 1vh;
  border-bottom-right-radius: 1vh;
}

.rdrDateRangePickerWrapper .rdrDateDisplayWrapper {
  border-top-right-radius: 1vh;
}

/* REACT SELECT */
.react-select {
  width: 100%;
  min-width: 16vh;
  cursor: pointer;
}

.react-select.label-select {
  min-width: 25vh;
}

.react-select.upload-select {
  width: 100%;
}

.react-select.dark.upload-select {
  width: 100%;
}

.react-select.labels {
  min-width: 15vh;
  width: auto;
}

.react-select.dark .account__control {
  background-color: transparent;
  cursor: pointer;
}

.react-select.dark
  .account__control
  .account__value-container
  .account__input-container {
  color: #fff;
}

.react-select.dark
  .account__control
  .account__value-container
  .account__single-value {
  color: #fff;
}

.react-select.dark .account__menu {
  background-color: #232327;
  border: 1px solid #424242;
}

.account__menu {
  z-index: 999 !important;
}

.account__menu .account__menu-list .account__menu-notice {
  font-size: 1.4vh;
  font-weight: 400;
}

.react-select.dark .account__menu .account__option {
  color: #fff;
}

.react-select.dark .account__menu .account__option--is-focused {
  background-color: rgba(213, 154, 74, 0.2);
}

.account__control {
  border: 0px solid transparent !important;
  min-height: auto !important;
  box-shadow: none !important;
}

.account__control .account__value-container {
  padding: 0px !important;
}

.react-select.dark .account__control .account__multi-value {
  background-color: #141418 !important;
}

.react-select.dark
  .account__control
  .account__multi-value
  .account__multi-value__label {
  font-size: 1.5vh !important;
  color: #fff;
}

.react-select
  .account__control
  .account__multi-value
  .account__multi-value__label {
  font-size: 1.5vh !important;
}

.react-select.dark
  .account__control
  .account__multi-value
  .account__multi-value__remove {
  color: #fff !important;
}

.account__control .account__value-container .account__placeholder {
  font-size: 1.5vh;
}

.account__control .account__value-container .account__single-value {
  font-size: 1.5vh;
}

.account__control .account__value-container .account__input-container {
  padding-bottom: 0px !important;
  margin: 0px !important;
  padding-top: 0px !important;
}

.account__control .account__indicators .account__indicator {
  padding-left: 5px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
}

.account__control
  .account__indicators
  .account__indicator.account__clear-indicator {
  display: none;
}

.account__control .account__indicators .account__indicator-separator {
  display: none;
}

.account__menu-list .account__option {
  font-size: 1.5vh;
  font-weight: 500;
  color: #4b4b4b;
}

.account__menu-list .account__option:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.account__menu-list .account__option--is-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

/* HIDE SCROLLBAR */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* CHARTS */
.doughnut-chart .apexcharts-canvas {
  width: 12vh !important;
  height: 9vh !important;
}

.doughnut-chart > div {
  min-height: auto !important;
  height: 9vh !important;
}

.doughnut-chart .apexcharts-canvas svg {
  width: 12vh !important;
  height: 9vh !important;
}

.doughnut-chart .apexcharts-canvas foreignObject {
  width: 12vh !important;
  height: 9vh !important;
}

/* REACT DATATABLE */
.react-datatable .rdt_Table {
  border-radius: 8px;
}

.react-datatable.dark .rdt_Table {
  background-color: #232327;
}

.react-datatable .rdt_Table .rdt_TableHead {
  background-color: #e5e5e5;
}

.react-datatable.dark .rdt_Table .rdt_TableHead {
  background-color: #232327;
}

.react-datatable.dark .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background-color: #232327;
  border-bottom: 0px solid transparent;
}

.react-datatable .rdt_Table .rdt_TableHead .rdt_TableCol {
  font-family: "ProximaNova-Semibold";
  font-size: 1.5vh;
  background-color: #f5f5f5;
}

.react-datatable.dark .rdt_Table .rdt_TableHead .rdt_TableCol {
  background-color: #424242;
  color: #fff;
}

.react-datatable .rdt_Table .rdt_TableHead .rdt_TableCol:first-child {
  border-top-left-radius: 8px;
}

.react-datatable .rdt_Table .rdt_TableHead .rdt_TableCol:last-child {
  border-top-right-radius: 8px;
}

.react-datatable.dark .rdt_Table .rdt_TableBody .rdt_TableRow {
  background-color: #232327;
}

.react-datatable.dark .rdt_Table .rdt_TableBody .rdt_TableRow:last-child {
  border-bottom-color: transparent !important;
}

.react-datatable.dark .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  border-bottom: 1px solid #424242;
}

/* CALENDAR */
.rbc-month-view {
  border-radius: 8px;
  border: 0px solid transparent;
  font-family: "ProximaNova-Regular" !important;
}

.rbc-month-view .rbc-row .rbc-header {
  min-height: 2vh;
  padding: 0.5vh 0px;
}

.rbc-off-range-bg {
  background: #f5f5f5;
}

.rbc-button-link {
  font-size: 1.5vh;
}

.rbc-today {
  background-color: rgba(66, 83, 240, 0.08);
}

.rbc-event {
  background-color: transparent !important;
  padding: 0px !important;
}

/* CALENDAR DARK */
.trades-calendar-dark .rbc-header {
  border-bottom: 1px solid #424242;
  color: #fff;
}

.trades-calendar-dark .rbc-day-bg {
  background-color: #141418;
}

.trades-calendar-dark .rbc-off-range-bg {
  background-color: #232327;
}

.trades-calendar-dark .rbc-button-link {
  color: #fff;
}

.trades-calendar-dark .rbc-today {
  background-color: rgba(245, 245, 245, 0.2);
}

.trades-calendar-dark .rbc-month-row + .rbc-month-row {
  border-top: 1px solid #424242;
}

.trades-calendar-dark .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #424242;
}

.trades-calendar-dark .rbc-header + .rbc-header {
  border-left: 1px solid #424242;
}

/* PROFILE TABS */
.tab-active > div {
  color: #d59a4a !important;
}

.tab-active > div svg {
  color: #d59a4a !important;
}

.backdrop-blur-sm {
  backdrop-filter: blur(0px);
}

.checkbox-control {
  border-radius: 0.4vh;
}

/* TRADE DETAILS TABLE */
.trade-table .rdt_Table {
  border-radius: 8px;
}

.trade-table .rdt_Table::-webkit-scrollbar {
  display: none;
}

.trade-table .rdt_Table .rdt_TableHead {
  background-color: #e5e5e5;
}

.trade-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  border-bottom: 0px solid transparent !important;
  min-height: 5.2vh !important;
}

.trade-table .rdt_Table .rdt_TableHead .rdt_TableCol {
  font-family: "ProximaNova-Semibold";
  font-size: 1.5vh;
  background-color: #f5f5f5;
}

.trade-table .rdt_Table .rdt_TableHead .rdt_TableCol:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.trade-table .rdt_Table .rdt_TableHead .rdt_TableCol:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.trade-table .rdt_Table .rdt_TableBody .rdt_TableCell {
  border-bottom: 1px solid #e5e5e5;
}

.trade-table .rdt_Table .rdt_TableBody .rdt_TableRow:last-child .rdt_TableCell {
  border-bottom: 0px solid transparent;
}

.trade-table .rdt_Table .rdt_TableBody .rdt_TableRow {
  border-bottom-width: 0px;
}

/* REACT QUILL */
.quill {
  height: 100%;
}

.quill .ql-toolbar {
  border: 1px solid #e5e5e5 !important;
  font-family: "ProximaNova-Semibold", sans-serif !important;
  border-top-left-radius: 1vh;
  border-top-right-radius: 1vh;
}

.dark-editor .quill .ql-toolbar {
  border: 1px solid #424242 !important;
}

.dark-editor .quill .ql-snow .ql-picker {
  color: #fff;
}

.dark-editor .quill .ql-snow .ql-formats {
  color: #fff;
}

.quill .ql-container {
  border: 1px solid #e5e5e5 !important;
  border-top: 0px solid transparent !important;
  font-family: "ProximaNova-Semibold", sans-serif !important;
  border-bottom-left-radius: 1vh;
  border-bottom-right-radius: 1vh;
}

.quill .ql-container .ql-editor {
  min-height: 25vh;
}

.dark-editor .quill .ql-container .ql-editor::before {
  color: #94a3b8;
}

.dark-editor .quill .ql-container {
  border: 1px solid #424242 !important;
  border-top: 0px solid transparent !important;
}

.dark-editor .quill .ql-toolbar .ql-formats button {
  background-color: #fff;
  border-radius: 2px;
  margin-left: 5px;
}

/* REACT QUILL IN MODAL */
.modal-box .ql-toolbar {
  border: 0px solid transparent !important;
  border-bottom: 1px solid #e5e5e5 !important;
}

.modal-box .ql-container {
  border: 0px solid transparent !important;
}

.modal-box .ql-container .ql-editor {
  min-height: 25vh;
  font-size: 1.7vh;
  color: #000;
}

.modal-box.dark .ql-toolbar {
  border-bottom: 1px solid #424242 !important;
}

.modal-box.dark .ql-container .ql-editor {
  min-height: 25vh;
  font-size: 1.7vh;
  color: #fff;
}

.modal-box.dark .ql-editor.ql-blank::before {
  color: #94a3b8;
  font-weight: 500;
  font-size: 1.5vh !important;
}

/* TAGS SELECT */
.tags-select .note-tag__control {
  border: 1px solid #e5e5e5 !important;
}

.tags-select-dark .note-tag__control {
  border: 1px solid #424242;
  background-color: #141418;
}

.tags-select
  .note-tag__control
  .note-tag__value-container
  .note-tag__placeholder {
  font-size: 1.4vh;
  font-weight: 400 !important;
}

.tags-select
  .note-tag__control
  .note-tag__value-container
  .note-tag__single-value {
  font-size: 1.4vh;
  font-weight: 500;
  text-transform: capitalize;
}

.tags-select-dark
  .note-tag__control
  .note-tag__value-container
  .note-tag__single-value {
  font-size: 1.4vh;
  font-weight: 500;
  text-transform: capitalize;
  color: #fff;
}

.tags-select-dark
  .note-tag__control
  .note-tag__value-container
  .note-tag__placeholder {
  font-size: 1.4vh;
  font-weight: 400 !important;
}

.tags-select-dark
  .note-tag__control
  .note-tag__value-container
  .note-tag__multi-value {
  background-color: #232327;
  color: #ffff;
}

.tags-select-dark
  .note-tag__control
  .note-tag__value-container
  .note-tag__multi-value
  .note-tag__multi-value__label {
  color: #fff;
}

.tags-select
  .note-tag__control
  .note-tag__indicators
  .note-tag__clear-indicator {
  padding: 4px;
  padding-right: 0px;
}

.tags-select-dark
  .note-tag__control
  .note-tag__indicators
  .note-tag__clear-indicator {
  display: none;
}

.tags-select
  .note-tag__control
  .note-tag__indicators
  .note-tag__indicator-separator {
  display: none;
}

.tags-select-dark
  .note-tag__control
  .note-tag__indicators
  .note-tag__indicator-separator {
  display: none;
}

.trades-active > div {
  color: #d59a4a;
}

/* TRADES TABLE */
.trades-table {
  border: 1px solid #e5e5e5;
  border-radius: 8px !important;
}

.trades-table.dark {
  border: 1px solid #424242;
}

.trades-table .rdt_Table {
  border-radius: 8px;
}

.trades-table .rdt_TableHead {
  font-family: "ProximaNova-Semibold", sans-serif !important;
  border-top-left-radius: 8px;
}

.trades-table .rdt_TableHeadRow {
  min-height: 4.5vh;
  background-color: rgba(245, 245, 245, 0.8);
  border-bottom: 1px solid #e5e5e5;
  border-top-left-radius: 8px;
}

.trades-table.dark .rdt_TableHeadRow {
  background-color: #141418;
  color: #fff;
  border-bottom: 1px solid #424242;
}

.trades-table .rdt_TableHeadRow .rdt_TableCol {
  font-size: 1.5vh;
}

.trades-table .rdt_TableHeadRow .rdt_TableCol_Sortable span {
  opacity: 1;
}

.trades-table .rdt_TableHeadRow .rdt_TableCol:first-child {
  border-top-left-radius: 8px;
}

.trades-table .rdt_Table .rdt_TableBody .rdt_TableCell {
  padding: 1.5vh;
}

.trades-table.dark .rdt_Table .rdt_TableBody .rdt_TableRow {
  background-color: #141418;
  border-bottom: 1px solid #424242;
  min-height: 4.5vh;
}

.trades-table .rdt_Table .rdt_TableBody .rdt_TableCell > div {
  text-overflow: clip;
  white-space: wrap;
  overflow: visible;
}

.dark-pagination .rdt_Pagination div select {
  background-color: #141418;
}

.dark-pagination .rdt_Pagination div select option {
  color: #fff;
}

/* TABLE PAGINATION */
.rdt_Pagination {
  margin-top: 2.1vh;
  font-family: "ProximaNova-Semibold", sans-serif !important;
  background-color: rgba(245, 245, 245, 0.5) !important;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  min-height: 4vh !important;
  font-size: 1.5vh;
}

.dark-pagination .rdt_Pagination {
  background-color: #141418 !important;
  border: 1px solid #424242;
  color: #fff;
  font-size: 1.5vh;
}

.dark-pagination .rdt_Pagination #pagination-first-page,
#pagination-last-page,
#pagination-previous-page,
#pagination-next-page {
  color: gray;
  fill: gray;
  height: 5vh;
  width: 5vh;
}

.dark-pagination .rdt_Pagination #pagination-previous-page svg {
  height: 2.5vh;
  width: 2.5vh;
}

.dark-pagination .rdt_Pagination #pagination-next-page svg {
  height: 2.5vh;
  width: 2.5vh;
}

.dark-pagination .rdt_Pagination #pagination-first-page svg {
  height: 2.5vh;
  width: 2.5vh;
}

.dark-pagination .rdt_Pagination #pagination-last-page svg {
  height: 2.5vh;
  width: 2.5vh;
}

.dark-pagination .rdt_Pagination > div select:focus {
  outline: none;
}

/* RESPONSIVE CSS */
@media (max-width: 575px) {
  .auth {
    height: auto;
  }

  .tradingview-widget-container iframe:first-child {
    height: 40vh !important;
  }

  .rdrDateRangePickerWrapper {
    display: block;
  }

  .rdrDefinedRangesWrapper {
    width: 100%;
  }

  .rdrInputRanges {
    padding: 0px;
  }

  .rdrDateRangePickerWrapper .rdrDateDisplayWrapper {
    border-top-right-radius: 0px;
  }
}

.Toastify .Toastify__toast-body {
  font-size: 1.5vh !important;
  font-family: "ProximaNova-Semibold", sans-serif !important;
}

/* READ ONLY EDITOR */
.readonly-editor .ql-container .ql-editor {
  min-height: auto !important;
  font-size: 1.6vh;
  padding: 0vh !important;
  margin-top: 1vh;
}

.readonly-editor .ql-container {
  font-family: "ProximaNova-Regular", sans-serif !important;
  border: 0px solid transparent !important;
}

.note-editor {
  border: 1px solid #e5e5e5;
}

.note-editor.dark {
  border: 1px solid #424242;
}

.note-editor.dark .ql-container .ql-editor p {
  color: #fff;
}

.loader-container {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}
.loader-white-box {
  position: relative;
  top: 50%;
  left: 50%;
}

.tradingview-widget-container iframe:first-child {
  height: 66vh !important;
}

.tradingview-widget-container iframe:nth-child(n + 2) {
  display: none !important;
}

.trade-editor .ql-editor {
  margin-top: 0vh !important;
}

.trade-editor.dark .ql-container .ql-editor::before {
  color: #fff;
}

emoji-slider {
  --emoji-slider-bar-color: #e5e5e5;
  --emoji-slider-bar-active-color: #d59a4a;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.tags-select-dark .note-tag__menu .note-tag__menu-list {
  background-color: #141418;
  color: #fff;
  border: 1px solid #232327;
  border-radius: 5px;
  box-shadow: none;
}

.tags-select-dark .note-tag__menu {
  border: 1px solid #232327;
}

.tags-select-dark
  .note-tag__menu
  .note-tag__menu-list
  .note-tag__option--is-focused {
  background-color: rgba(213, 154, 74, 0.1);
}

.tags-select-dark .note-tag__menu .note-tag__menu-list .note-tag__option {
  font-size: 1.5vh;
  text-transform: capitalize;
}

.tags-select-dark
  .note-tag__menu
  .note-tag__menu-list
  .note-tag__option--is-selected {
  background-color: rgb(213, 154, 74);
}

.tags-select .note-tag__menu .note-tag__menu-list {
  max-height: 10vh;
}

.tags-select-dark .note-tag__menu .note-tag__menu-list {
  max-height: 10vh;
}

.tags-select
  .note-tag__menu
  .note-tag__menu-list
  .note-tag__option--is-focused {
  background-color: rgba(213, 154, 74, 0.1);
}

.tags-select
  .note-tag__menu
  .note-tag__menu-list
  .note-tag__option--is-selected {
  background-color: rgb(213, 154, 74);
}

.tags-select .note-tag__menu .note-tag__menu-list .note-tag__option {
  font-size: 1.5vh;
  text-transform: capitalize;
}

.apexcharts-text {
  font-size: 1.3vh !important;
}

.no-data .rdt_Table > div {
  background-color: transparent !important;
}

.calendarDark .rdrDefinedRangesWrapper {
  background-color: #232327;
  border-right: 1px solid #424242;
}

.calendarDark .rdrDefinedRangesWrapper .rdrStaticRanges .rdrStaticRange {
  border-bottom: 1px solid #424242;
}

.calendarDark
  .rdrDefinedRangesWrapper
  .rdrStaticRanges
  .rdrStaticRange
  .rdrStaticRangeLabel:hover {
  background-color: rgb(213, 154, 74, 0.1) !important;
}

.calendarDark .rdrDefinedRangesWrapper .rdrStaticRanges button span {
  color: white;
}

.calendarDark .rdrCalendarWrapper .rdrDateDisplayWrapper {
  background-color: #232327;
}

.calendarDark
  .rdrCalendarWrapper
  .rdrDateDisplayWrapper
  .rdrDateDisplay
  .rdrDateDisplayItem {
  background-color: #141418;
  color: #d59a4a;
}

.calendarDark
  .rdrCalendarWrapper
  .rdrDateDisplayWrapper
  .rdrDateDisplay
  .rdrDateDisplayItem
  input {
  color: #fff;
}

.calendarDark .rdrCalendarWrapper .rdrMonthAndYearWrapper {
  background-color: #232327;
  padding-top: 0px;
}

.calendarDark
  .rdrCalendarWrapper
  .rdrMonthAndYearWrapper
  .rdrMonthAndYearPickers
  span
  select {
  color: #fff;
}

.calendarDark
  .rdrCalendarWrapper
  .rdrMonthAndYearWrapper
  .rdrMonthAndYearPickers
  span
  select
  option {
  color: #fff;
}

.calendarDark .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrPprevButton i {
  border-color: transparent white transparent transparent;
}

.calendarDark .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextButton i {
  border-color: transparent transparent transparent white;
}

.calendarDark .rdrCalendarWrapper .rdrMonths {
  background-color: #232327;
  height: 100%;
}

.rdrCalendarWrapper .rdrMonthAndYearWrapper {
  padding-top: 0px;
}

.calendarDark .rdrDayNumber span {
  color: #fff !important;
}

.calendarDark
  .rdrMonthAndYearWrapper
  .rdrMonthAndYearPickers
  .rdrMonthPicker
  select {
  background-color: #232327;
}

.calendarDark
  .rdrMonthAndYearWrapper
  .rdrMonthAndYearPickers
  .rdrYearPicker
  select {
  background-color: #232327;
}

.react-datetime .rdtTabs .rdtActive {
  border: none !important;
}

.new-datepicker input {
  background-color: transparent;
}

.new-datepicker input:focus {
  outline: none;
}

.new-datepicker.dark .rdtPicker {
  color: #fff;
  background-color: #141418;
  border: 1px solid #424242;
}

.tabs-show > div {
  overflow: visible !important;
}

.show-more-less-clickable {
  color: #d59a4a;
  cursor: pointer;
}

.rr-chart .apexcharts-legend-text {
  color: #fff !important;
}

.def-cursor {
  cursor: default;
}

.z-top {
  z-index: 9999 !important;
}

.badge-gradient {
  background-color: #d2a813;
  background-image: linear-gradient(315deg, #d2a813 0%, #000000 74%);
}

#tidio-chat-iframe {
  bottom: 10px !important;
  left: 0px !important;
}

.tv-lightweight-charts {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px !important;
}

.Toastify__toast-container {
  width: 35vh;
}
